body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* background-color: #040609 !important; */
	height: 100%;
}
.customOverlay {
	background: black;
}

/* MODALS */
.closeModel{
	background-color: #00DFFF !important;
}
.customModal {
	background: #040609 !important;
	width: 100%;
	padding: 1px;
	border-radius: 4px;
	box-shadow: 0 0px 33px 0 #ffffff40 !important;
}
.customModalLight {
	background: #ffffff !important;
	width: 100%;
	padding: 1px;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgb(255 255 255 / 20%) !important;
}
.customModalNew {
	background: #040609 !important;
	min-height: 160px;
	width: 460px;
	border-radius: 4px;
	box-shadow: 0 0px 33px 0 #ffffff40 !important;
}
.customModalNewLight {
	background: #ffffff !important;
	min-height: 160px;
	width: 460px;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgb(255 255 255 / 20%) !important;
}
.helpModal {
	width: 24rem;
	height: 29rem;
	border-radius: 6px;
	background: #040609 !important;
	padding: 0 !important;
	box-shadow: 0px 3px 15px rgb(255 255 255 / 20%) !important;
}

.helpModalLightMode {
	width: 24rem;
	height: 29rem;
	border-radius: 6px;
	background: #ffffff !important;
	padding: 0 !important;
	box-shadow: 0px 3px 15px rgb(255 255 255 / 20%) !important;
}

/* MODALS */

/* DATE PICKER */

.react-datepicker__input-container input {
	display: flex !important;
	justify-content: center !important;
	text-align: center !important;
	/* all: unset !important; */
	padding:0.3rem 0rem;
	color: #f0f0f0f0 !important;
	border: 1px solid #2596be !important;
	border-radius: 0.3rem !important;
	background: black !important;
	outline: none !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
	border-top: unset !important;
	border-bottom-color: transparent !important;
}

/* DATE PICKER */

/* width */
::-webkit-scrollbar {
	width: 3px !important;
	height: 3px !important;
}
/*
::-webkit-scrollbar-track {
    background: black !important;
}


::-webkit-scrollbar-thumb {
    background: #00dfff !important;
}


::-webkit-scrollbar-thumb:hover {
    background: #00dfff !important;
} */

@font-face {
	font-family: 'Montserrat-Regular';
	src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Montserrat-Medium';
	src: local('Montserrat-Medium'), url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'bicubik';
	src: local('bicubik'), url('./assets/fonts/bicubik/bicubik.otf') format('truetype');
}

h2,
h3 {
	font-family: 'bicubik';
	letter-spacing: 0.1rem;
	/* font-family: roboto-regular; */
}
h4 {
	font-family: 'bicubik';
	letter-spacing: 0.1rem;
	/* font-family: roboto-regular; */
}

h1,
h4,
h5 {
	font-family: 'bicubik';
	letter-spacing: 0.05em;
}

p {
	margin-top: 0 !important;
	margin-bottom: 0rem !important;
	font-family: 'Montserrat-Regular', sans-serif;
}
label {
	display: inline-block;
	margin-bottom: 0rem !important;
}
span {
	font-family: 'Montserrat-Regular', sans-serif;
}

td {
	font-family: 'Montserrat-Regular', sans-serif;
}
th {
	font-family: 'bicubik';
	letter-spacing: 0.1em;
	/* font-size: 0.9rem !important; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

canvas {
	z-index: 1 !important;
}

/* TABLE */
.table > :not(caption) > * > * {
	box-shadow: none !important;
	background-color: transparent !important;
}
.table-responsive {
	width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
}

#darkModelCalender .react-datepicker{
	background-color: #0000 !important;
    color: #f0f0f0 !important;
    border: 1px solid #000000 !important;
}
#darkModelCalender .react-datepicker__day-name, #darkModelCalender .react-datepicker__day, #darkModelCalender .react-datepicker__time-name{
	color: #f0f0f0 !important;
}

#darkModelCalender .react-datepicker__header{
	background-color: #0000 !important;
}
#darkModelCalender .react-datepicker__current-month, #darkModelCalender .react-datepicker-time__header, #darkModelCalender .react-datepicker-year-header{
	color: #f0f0f0 !important;
}
#darkModelCalender .react-datepicker__time-container .react-datepicker__time{
	background-color: #0000 !important;
}

#darkModelCalender .react-datepicker__day:hover, #darkModelCalender .react-datepicker__month-text:hover, #darkModelCalender .react-datepicker__quarter-text:hover, #darkModelCalender .react-datepicker__year-text:hover, #darkModelCalender .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
	background-color: #333 !important;
}
#darkModelCalender .react-datepicker__time-container{
	border-left: 1px solid #3330 !important;
}

#darkModelCalender .react-datepicker__header{
	border-bottom: 1px solid #3330 !important;;
}

#lightModelCalender .react-datepicker{
    border: 1px solid #fff !important;
}

#lightModelCalender .react-datepicker__header{
	border-bottom: 1px solid #fff !important;;
}

#lightModelCalender .react-datepicker__time-container{
	border-left: 1px solid #fff !important;
}

#lightModelCalender .react-datepicker__header{
	background-color: #fff;
}

#lightModelCalender .react-datepicker__input-container input{
	background: #fff !important;
	color: #333 !important;
}


